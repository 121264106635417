<template>
  <b-card-code title="Modifier un Dépôt">
    <b-card-text>
      <span>Veuillez modifier les coordonnées de dépôt </span>
    </b-card-text>

    <validation-observer ref="simpleRules">
      <b-form v-if="repository">
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Dépôt"
              label-for="Dépôt"
            >
              <validation-provider
                #default="{ errors }"
                name="Dépôt"
                rules="required"
              >
                <b-form-input
                  v-model="repository.name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Dépôt"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :class="{ 'disabled-cursor': isLoading || !isRepositoryValid }"
              :disabled="isLoading || !isRepositoryValid"
              @click.prevent="editRepository"
            >
              Modifier
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
} from 'bootstrap-vue'
import { required } from '@validations'
import axios from 'axios'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      required,
      repository: [],
      isLoading: false,
    }
  },
  computed: {
    isRepositoryValid() {
      return (
        this.repository.name !== ''
      )
    },
  },
  created() {
    this.getRepository()
  },
  methods: {
    async getRepository() {
      await axios.get(`/api/repositories/detail/${this.$route.params.id}`).then(response => {
        this.repository = response.data
      })
    },
    async editRepository() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            await axios
              .put(
                `/api/repositories/update/${this.$route.params.id}/`,
                {
                  name: this.repository.name,
                },

              )
            this.$router.push('/repositories/')
            setTimeout(() => {
              this.showToast('success', 'top-center', 'Dépôt modifié avec succés')
            }, 1000)
          } catch (error) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', error.toString())
            }, 1000)
          }
        } else {
          setTimeout(() => {
            this.showToast('danger', 'top-center', 'champ obligatoire ! vérifier votre donnée !')
          }, 1000)
        }
      })
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
